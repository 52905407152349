import React from 'react';

import PageLayout from '../components/pageLayout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <PageLayout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>The page you&apos;ve requested does not exist.</p>
  </PageLayout>
);

export default NotFoundPage;
